<template>
  <div class="w-100 d-flex flex-row align-items-center justify-content-center">
    <b-row
      class="d-flex align-items-center justify-content-center w-50"
      style="height: 60vh;"
    >
      <b-col>
        <b-img-lazy
          :src="require('@/assets/images/logo/track.png')"
          style="width: 260px"
        />
      </b-col>
      <b-col>
        <h2 class="text-primary font-weight-bolder mb-2">
          TRACK & TRACE
        </h2>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-form-group
              class="text-primary"
              label="Delivery ID or Order ID"
            >
              <validation-provider
                #default="{ errors }"
                name="Delivery ID or Order ID"
                rules="required"
              >
                <b-form-input
                  v-model="form.id"
                  placeholder="Enter Delivery ID or Order ID..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              type="submit"
              variant="danger"
              class="mr-1 mt-2"
              block
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },
  data() {
    return {
      form: {
        id: null,
      },
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          let response
          response = await this.$store.dispatch('trackAndTrace/fetchOrder', { orderId: this.form.id })
          if (response.status === 200) {
            this.$router.replace(`/order-tracking/${this.form.id}`)
            return
          }
          response = await this.$store.dispatch('trackAndTrace/fetchDeliveries', { deliveryId: this.form.id })
          if (response.status === 200) {
            this.$router.replace(`/delivery-tracking/${this.form.id}`)
          }

          if (response.status === 404) {
            this.$swal({
              title: 'ID does not exist',
              text: 'The Delivery / Order ID does not exist. Please check your entry and try again.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn-primary',
              },
            })
          }
        }
      })
    },
  },
}
</script>
